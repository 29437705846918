<template>
  <div id="login">
    <div id="loginBox">
      <div class="logo">
        <img src="../../assets/logo.png" alt="">
      </div>
      <h4 class="login-title theme-color">简投实业消纳场票据系统</h4>
      <el-form
        :model="loginForm"
        :rules="loginRules"
        ref="loginForm"
        label-width="0px"
      >
        <el-form-item label="" prop="userName" style="margin-top: 40px">
          <el-row>
            <el-col :span="22">
              <el-input
                clearable
                class="inps"
                prefix-icon="el-icon-user"
                placeholder="用户名"
                v-model="loginForm.userName"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="" prop="passWord">
          <el-row>
            <el-col :span="22">
              <el-input
                show-password
                clearable
                class="inps"
                prefix-icon="el-icon-unlock"
                placeholder="密码"
                v-model="loginForm.passWord"
                type="password"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item style="margin-top: 55px">
          <el-button type="primary" size="medium" class="inputW" @click="submitForm">登 录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import { mapActions } from 'vuex';
import { $gp_login } from '../../api/index';

export default {
  data() {
    return {
      loginForm: {
        userName: '',
        passWord: '',
      },
      loginRules: {
        userName: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        passWord: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
    };
  },
  methods: {
    ...mapActions(['setUserInfo']),
    // 提交登录
    submitForm() {
      const params = {
        username: this.loginForm.userName,
        password: this.loginForm.passWord,
      };
      if (!params.username) {
        this.$message.error('请输入你的账号');
        return;
      }
      if (!params.password) {
        this.$message.error('密码没有输入哦~');
        return;
      }
      $gp_login(params).then((res) => {
        console.log(res);
        this.setUserInfo(res);
        this.$storage.set('userInfo', res);
        console.log(res.role === 'QTC_MANAGER');
        this.$router.push('./');
      });
    },

  },
  mounted() {
  },
};
</script>

<style lang='scss' scoped>
#login {
  width: 100vw;
  padding: 0;
  margin: 0;
  height: 100vh;
  font-size: 16px;
  background-repeat: no-repeat;
  background-position: left top;
  background-color: #f2f2f2;
  color: #fff;
  font-family: "Source Sans Pro";
  background-size: 100%;
  position: relative;
  #bgd {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }
  #loginBox {
    width: 500px;
    height: 580px;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: 60px;
    border-radius: 6px;
    opacity: 1;
    color: #666;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    &:hover{
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.6);
      transition: all 0.4s;
    }
    background: #fff;
    .logo{
      width: 100px;
      height: 100px;
      margin: auto;
      img{
        width: 100%;
      }
    }
    .login-title{
      margin-top: 20px;
      margin-bottom: 60px;
      text-align: center !important;
      font-size: 28px;
      font-weight: bold;
    }
    ::v-deep .inps{
      border: none;
      color: #666;
      background-color: transparent;
      font-size: 14px;
      width: 380px;
      // height: 50px;
    }
    .inputW {
      width: 380px;
      height: 50px;
      font-size: 18px;
    }
    .iconfont {
      color: #fff;
    }
  }
}
</style>
